import { createSlice } from "@reduxjs/toolkit";

const initialState = []; 

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {}
});
// export const { addProduct, removeProduct } = expurgoSlice.actions;
export const template = (state) => state.template;
export default templateSlice.reducer;

