import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard           = lazy(() => import('./user-pages/dashboard/Dashboard'));

const Colaboradores       = lazy(() => import('./user-pages/cadastros/colaboradores'));
const ContainerFormColaboradores       = lazy(() => import('./user-pages/cadastros/colaboradores/containerFormColaboradores.js'));
const Relatorios          = lazy(() => import('./user-pages/relatorios'));
const InformacoesDoPaciente          = lazy(() => import('./user-pages/Etapas/InformacoesDoPaciente'));
const SignIn          = lazy(() => import('./user-pages/Etapas/SignIn'));

const Error404            = lazy(() => import('./user-pages/Error404'));
const Error500            = lazy(() => import('./user-pages/Error500'));

const Login               = lazy(() => import('./user-pages/Login'));
const Register1           = lazy(() => import('./user-pages/Register'));

const BlankPage           = lazy(() => import('./user-pages/BlankPage'));


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route path="/user-pages/login-1" component={ Login } />
          <Route path="/user-pages/register-1" component={ Register1 } />

          <Route exact path="/dashboard" component={ Dashboard } />
          
          <Route exact path="/start" component={ InformacoesDoPaciente } />
          <Route exact path="/sign-in" component={ SignIn } />
          

          <Route exact path="/cadastros/colaboradores" component={ Colaboradores } />
          <Route exact path="/cadastros/colaboradores/cadastrar" component={ ContainerFormColaboradores } />
          <Route exact path="/cadastros/colaboradores/editar/:key" component={ ContainerFormColaboradores } />
          
          <Route exact path="/relatorios" component={ Relatorios } />


          <Route path="/user-pages/error-404" component={ Error404 } />
          <Route path="/user-pages/error-500" component={ Error500 } />

          <Route path="/user-pages/blank-page" component={ BlankPage } />


          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;