import React, {useRef} from "react";


export const Comandos = (state) => {

    var resp = false

    const condicaoOnText = state.estadoAtualComando === 'onText' ? false : true;
 
    const comandos = {
        'start': () => window.location.href="/start",
        'preencher início':  {action_id: 'preencherDataInicio', estadoAtualComando: 'onText'},
        'preencher término':  {action_id: 'preencherDataTermino', estadoAtualComando: 'onText'},
        'preencher paciente':  {action_id: 'preencherNomePaciente', estadoAtualComando: 'onText'},
        'preencher data de nascimento':  {action_id: 'preencherDataDeNascimento', estadoAtualComando: 'onText'},
        'preencher peso':  {action_id: 'preencherPeso', estadoAtualComando: 'onText'},
        'preencher isolamento não':  {action_id: 'preencherIsolamentoNao', estadoAtualComando: 'onAction'},
        'preencher isolamento sim':  {action_id: 'preencherIsolamentoSim', estadoAtualComando: 'onAction'},
        'prosseguir':  {action_id: 'seguirParaOProximo', estadoAtualComando: 'onAction'},
        'preencher tipo de isolamento':  {action_id: 'preencherTipoDeIsolamento', estadoAtualComando: 'onText'},
        'preencher cirurgião':  {action_id: 'preencherCirurgiao', estadoAtualComando: 'onText'},
        'preencher instrumentador':  {action_id: 'preencherInstrumentador', estadoAtualComando: 'onText'},
        'preencher procedimento':  {action_id: 'preencherProcedimento', estadoAtualComando: 'onText'},
        'fechar edição':  {action_id: '', estadoAtualComando: 'onAction'},
        'limpar': {action_id: 'limparCampoSelecionado', estadoAtualComando: 'onAction'},

    }

    Object.keys(comandos).map(key => {

        if(state.ultimaFala && state.ultimaFala.indexOf(key) > -1){
            resp = comandos[key]
            return
        }
    })

    return resp

}