import { createSlice } from "@reduxjs/toolkit";

const initialState = {
                        etapa: 1,
                        etapasURL: ['', '/start','/sign-in','/time-out','/sign-out'],
                        paciente: {}
                     };

export const timeoutSlice = createSlice({
    name: 'timeout',
    initialState,
    reducers: {
        setPaciente: (state, action) => {
            state.paciente = action.payload
        },
        setEtapa: (state, action) => {
            state.etapa = action.payload
        },
        setDadosTimeout: (state, action) => {
            Object.keys(action.payload).map((val, key) => {
                if(state[val] !== 'undefined'){
                    state[val] = action.payload[val]
                }
            })
        }
    }
});

export const { setDadosTimeout, setPaciente, setEtapa }  = timeoutSlice.actions;
export const dadosTimeout = (state) => state.timeout;


export default timeoutSlice.reducer;

