import React, { useEffect, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { dadosComandosPorVoz, setUltimaFala, setDadosComandoVoz } from "../../../redux/slices/comandoPorVozSlice";
import { useSelector, useDispatch } from 'react-redux';
import { Comandos } from "./comandos";

const ComandoPorVoz = () => {

    const {transcript, resetTranscript} = useSpeechRecognition()

    const dadosPorVoz = useSelector(dadosComandosPorVoz)
    const dispatch = useDispatch();

    const timerSpeach = useRef('')

    useEffect(() => {
        SpeechRecognition.startListening({continuous: true})
    },[])

    /** Atualiza a Ultima Fala do usuário */
    useEffect(() => {


        if(transcript === ''){
            return;
        }

            dispatch(setUltimaFala(transcript))
     

    }, [transcript])


    /** Detecta a ultima fala dita pelo usuário */
    useEffect(() => {
        window.clearTimeout(timerSpeach.current)

        if(dadosPorVoz.ultimaFala === ''){
            return
        }
        
        var call = Comandos(dadosPorVoz)

        if(typeof call === 'function'){
            call()
        }
        if(typeof call === 'object'){
            
            dispatch(setDadosComandoVoz(call))

            if(call.estadoAtualComando == 'onText'){
                
            }


        }




        window.setTimeout(() => {
            dispatch(setUltimaFala(''))
            resetTranscript();
        }, 3000)
    }, [dadosPorVoz.ultimaFala])

    if(transcript.length === 0){
        return ''
    }
    return <>
            <span style={{backgroundColor:"#00000070", color:"#FFF", padding:"10px", position:"absolute", bottom:"50px", left:"25px", fontSize:"18px"}}>{transcript}</span>
        </>

}

export default ComandoPorVoz