import { createSlice } from "@reduxjs/toolkit";

const initialState = {
                        lista: [],
                        dadosColaborador: {
                            id: null,
                            nome: '',
                            sobrenome: '',
                            cpf: '',
                            telefone: '',
                            email: '',
                            endereco: '',
                            numero: '',
                            bairro: '',
                            cargo: '',
                            cracha: '',
                            coren: '',
                            data_admissao: '',
                            usuario: '',
                            senha: '',
                            empresa_id: '',
                            niveis_acesso: {},
                            data_criacao: ''
                        }
                     };

export const colaboradorSlice = createSlice({
    name: 'colaborador',
    initialState,
    reducers: {
        getColaborador: (state, action) => {
            state.dadosColaborador = {
                                        id: 1,
                                        nome: 'Alan Junior',
                                        sobrenome: 'Alencar Prado',
                                        cpf: '055.474.971-85',
                                        telefone: '67 999395223',
                                        email: 'alanjralencarp@gmail.com',
                                        endereco: 'Rua Irineu Bonicontro',
                                        numero: '447',
                                        bairro: 'Jardim Progresso',
                                        cargo: 'Enfermeiro',
                                        cracha: '1267864',
                                        coren: '924672',
                                        data_admissao: '2021-09-20',
                                        usuario: '',
                                        senha: null,
                                        empresa_id: null,
                                        niveis_acesso: {},
                                        data_criacao: null
                                    }
        },
        setDadosColaborador: (state, action) => {
            Object.keys(action.payload).map((val, key) => {
                if(typeof state.dadosColaborador[val] !== 'undefined'){
                    state.dadosColaborador[val] = action.payload[val]
                }
            })
        }
    }
});

export const { getColaborador , setDadosColaborador}  = colaboradorSlice.actions;
export const listColaborador    = (state) => state.colaborador.lista;
export const dadosColaborador    = (state) => state.colaborador.dadosColaborador;


export default colaboradorSlice.reducer;

