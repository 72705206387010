import { createSlice } from "@reduxjs/toolkit";

const initialState = {
                        ultimaFala: false,
                        estadoAtualComando: 'onAction', // onText, onAction
                        action_id: false // valor monitorado pelos componentes

                     };

export const comandoPorVozSlice = createSlice({
    name: 'comandoporvoz',
    initialState,
    reducers: {
        setUltimaFala: (state, action) => {
            state.ultimaFala = action.payload
        },
        setDadosComandoVoz: (state, action) => {
            Object.keys(action.payload).map((val, key) => {
                if(state[val] !== 'undefined'){
                    state[val] = action.payload[val]
                }
            })
        }
    }
});

export const { setDadosComandoVoz, setUltimaFala }  = comandoPorVozSlice.actions;
export const dadosComandosPorVoz = (state) => state.comandoporvoz;


export default comandoPorVozSlice.reducer;

