import { configureStore } from '@reduxjs/toolkit';

import templateReducer from '../slices/templateSlice';
import colaboradorReducer from '../slices/colaboradorSlice';
import comandoPorVozReducer from '../slices/comandoPorVozSlice';
import timeoutReducer from '../slices/timeoutSlice';

export const store = configureStore({
    reducer: {
        template: templateReducer,
        colaborador: colaboradorReducer,
        comandoporvoz: comandoPorVozReducer,
        timeout: timeoutReducer
    }
});
